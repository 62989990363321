import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Chose',
    // component: Home
    component: () => import('../views/Chose.vue')

  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home2.vue')

  },
  {
    path: '/sign',
    name: 'Sign',
    // component: () => import('../views/Sign.vue')
    component: () => import('../views/SignUp.vue')

  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/Mobile.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')//关于赛事mobile
  },
  {
    path: '/aboutss',
    name: 'Aboutss',
    component: () => import('../views/Aboutss.vue')//关于赛事PC
  },
  {
    path: '/registered',
    name: 'Registered',
    component: () => import('../views/Registered.vue')//注册
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../views/Password.vue')//忘记密码
  },
  {
    path: '/saidao1',
    name: 'Saidao1',
    component: () => import('../views/Saidao1.vue')//赛道一
  },
  {
    path: '/saidao2',
    name: 'Saidao2',
    component: () => import('../views/Saidao2.vue')//赛道二
  },
  {
    path: '/saidao3',
    name: 'Saidao3',
    component: () => import('../views/Saidao3.vue')//赛道三
  },  
  {
    path: '/saidao4',
    name: 'Saidao4',
    component: () => import('../views/Saidao4.vue')//赛道四
  },  
  {
    path: '/saidao5',
    name: 'Saidao5',
    component: () => import('../views/Saidao5.vue')//赛道五
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/Upload.vue')//上传作品
  },
  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import('../views/Regulations.vue')//用户隐私条列
  },
]

const router = new VueRouter({
  routes
})

export default router
