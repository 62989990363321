<template>
  <div class="home">
    <div class="top flex">
      <img class="logo" @click="goHome('')" src="@/assets/home1/logo@2x.png" alt="">
      <div class="flex">
        <div @click="id='about'" :class="[id=='about'? 'nav active ' :'nav']"><a href="#about">关于赛事</a><span v-if="id=='about'"></span></div>
        <div @click="id='format'" :class="[id=='format'? 'nav active ' :'nav']"><a href="#format">赛事赛制</a><span v-if="id=='format'"></span></div>
        <div @click="id='review'" :class="[id=='review'? 'nav active ' :'nav']"><a href="#review">评审介绍</a><span v-if="id=='review'"></span></div>
        <div @click="id='sign'" :class="[id=='sign'? 'nav active ' :'nav']"><a href="#sign">赛事报名</a><span v-if="id=='sign'"></span></div>
      </div>
    </div>
    <img class="banner" src="@/assets/home1/banner@2x.png" alt="">
    <!-- 关于赛事 -->
    <div id="about" class="part2">
      <img class="line01" src="@/assets/home1/01@2x.png" alt="">
      <img class="gyss" src="@/assets/home1/gyss@2x.png" alt="">
      <img class="jx" src="@/assets/home1/jx@2x.png" alt="">
      <img class="kv" src="@/assets/home1/KV@2x.png" alt="">
      <img class="about" src="@/assets/home1/about@2x.png" alt="">
      <img class="wycs" src="@/assets/home1/wycs@2x.png" alt="">
      <img class="line02" src="@/assets/home1/02@2x.png" alt="">
      <img class="line03" src="@/assets/home1/03@2x.png" alt="">
      <img class="bcss" src="@/assets/home1/bcss@2x.png" alt="">
      <div class="piclist flex">
        <img class="ss" src="@/assets/home1/ss01@2x.png" alt="">
        <img class="ss" src="@/assets/home1/ss02@2x.png" alt="">
        <img class="ss" src="@/assets/home1/ss03@2x.png" alt="">
        <img class="ss" src="@/assets/home1/ss04@2x.png" alt="">
      </div>
    </div>
    <!-- 赛事赛制 -->
    <div id="format" class="part3">
      <img class="sssz" src="@/assets/home1/sssz@2x.png" alt="">
      <img class="banner02" src="@/assets/home1/banner02@2x.png" alt="">
      <img class="line04" src="@/assets/home1/04@2x.png" alt="">
      <div class="piclist02 flex-between">
        <div @click="activecard=index" :class="[activecard==index?'activecard':'card']" v-for="(item,index) in cardList" :key="index">
            <img :src="item.src" alt="">
            <div class="text1">{{item.title}}</div>
            <div class="text2">{{item.info}}</div>
        </div>
      </div>
      <img v-if="activecard== 0" class="picinfo" src='@/assets/home1/info01@2x.png' alt="">
      <img v-if="activecard== 1" class="picinfo" src='@/assets/home1/info02@2x.png' alt="">
      <img v-if="activecard== 2" class="picinfo" src='@/assets/home1/info03@2x.png' alt="">
      <img v-if="activecard== 3" class="picinfo" src='@/assets/home1/info04@2x.png' alt="">
      <img v-if="activecard== 4" class="picinfo" src='@/assets/home1/info05@2x.png' alt="">
      <img class="line05" src="@/assets/home1/05@2x.png" alt="">
    </div>
    <!-- 评审介绍 -->
    <div id="review" class="part4">
      <img class="psjs" src="@/assets/home1/psjs@2x.png" alt="">
      <img class="title01" src="@/assets/home1/title01@2x.png" alt="">
      <img class="peo01" src="@/assets/home1/peo01@2x.png" alt="">
      <img class="title02" src="@/assets/home1/title02@2x.png" alt="">
      <div class="text3">四位设计领域浸润多年的资深评委从专业角度,关注常熟文化符号的传达和生活方式的更新</div>
      <img class="peo02" src="@/assets/home1/peo02@2x.png" alt="">
      <img class="peo03" src="@/assets/home1/peo03@2x.png" alt="">
      <img class="peo04" src="@/assets/home1/peo04@2x.png" alt="">
      <img class="peo05" src="@/assets/home1/peo05@2x.png" alt="">
      <img class="line06" src="@/assets/home1/06@2x.png" alt="">

    </div>
    <!-- footer -->
    <div id="sign" class="footer">
      <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
      <div class="text-wrap flex-vcbetween">
        <div>
          <div><span>指导单位</span>中共常熟市委宣传部</div>
          <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
          <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
        </div>
        <div class="flex-vc">
          <div><span>官方热线:</span></div>
          <div>
            <div>邮箱: 962991363@qq.com</div>
            <div>微信: 13817518262</div>
          </div>
        </div>
      </div>
      <div class="text-bottom flex-between">
        <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="color:#fff">wuyuchangshu.</span> ALL Right Reserved</div>
        <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      cardList: [
        {
          src: require('@/assets/home1/img02@2x.png'),
          title: '赛道一' ,
          info: '常熟文创形象“虞小主”卡通形象设计'
        },
        {
          src: require('@/assets/home1/img02@2x.png'),
          title: '赛道二' ,
          info: '常熟大米产品包装设计'
        },
        {
          src: require('@/assets/home1/img02@2x.png'),
          title: '赛道三' ,
          info: '虞山碧螺春包装设计'
        },
        {
          src: require('@/assets/home1/img02@2x.png'),
          title: '赛道四' ,
          info: '常熟文化主题生活用品设计'
        },

        {
          src: require('@/assets/home1/img02@2x.png'),
          title: '赛道五' ,
          info: '“无界常熟”“文创物品” 创新设计开放命题'
        },
      ],
      id: 'about',
      activecard: 0
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    height: 134px;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .active {
      font-weight: bold;
      color: #231815;
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
  .banner {
    width: 1920px;
    display: block;
  }
  .part2 {
    position: relative;
    height: 1165px;
    .line01 {
      position: absolute;
      left: 375px;
      top: 0;
      height: 98px;
    }
    .gyss {
      width: 682px;
      margin: 56px 0 68px 0;
    }
    .jx {
      position: absolute;
      top: 209px;
      left: 673px;
      width: 446px;
    }
    .kv {
      width: 426px;
      position: absolute;
      top: 209px;
      left: 1119px;
    }
    .about {
      width: 420px;
      position: absolute;
      left: 375px;
      top: 284px;
    }
    .wycs {
      width: 372px;
      position: absolute;
      left: 375px;
      top: 344px;
    }
    .line03 {
      width: 130px;
      position: absolute;
      top: 575px;
      left: 375.5px;
    }
    .line02 {
      width: 146px;
      position: absolute;
      left: .5px;
      top: 724px;
    }
    .bcss {
      width: 567px;
      top: 567px;
      left: 526px;
      position: absolute;
    }
    .piclist {
      position: absolute;
      top: 813px;
      left: 375px;
      .ss {
        width: 280px;
        margin-right: 16.67px;
      }
    }
  }
  .part3 {
    position: relative;
    height: 1103px;
    .sssz {
      margin-bottom: 42px;
      width: 682px;
    }
    .banner02 {
      height: 210px;
      display: block;
    }
    .line04 {
      width: 146px;
      left: 1413.5px;
      top: 180px;
      position: absolute;
    }
    .line05 {
      height: 52px;
      position: absolute;
      left: 1567px;
      top: 1005.5px;
    }
    .piclist02 {
      width: 1170px;
      position: absolute;
      left: 352px;
      top: 222px;
      .activecard {
        width: 224px;
        height: 266px;
        background: #000000;
        border: 4px solid #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.30);
        border-radius: 4px;
        img {
          width: 114px;
          margin: 27px 0 22px 0;
        }
        .text1 {
          font-size: 20px;
          color: #FFFFFF;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .text2 {
          font-size: 16px;
          color: #FFFFFF;
          width: 166px;
          margin: 0 auto;
        }
      }
      .card {
        width: 224px;
        height: 266px;
        background: #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.30);
        border-radius: 4px;
        img {
          width: 114px;
          margin: 27px 0 22px 0;
        }
        .text1 {
          font-size: 20px;
          color: #23242A;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .text2 {
          font-size: 16px;
          color: #333333;
          width: 166px;
          margin: 0 auto;
        }
      }
    }
    .picinfo {
      top: 530px;
      left: 352px;
      position: absolute;
      width: 1568px;
    }
  }
  .part4 {
    height: 1312px;
    position: relative;
    background: #F2F3F4;
    .psjs {
      width: 682px;
      margin: 66px auto 51px;
    }
    .title01 {
      width: 116px;
      position: absolute;
      top: 202px;
      left: 360px;
    }
    .peo01 {
      position: absolute;
      width: 1170px;
      top: 257px;
      left: 375px;
    }
    .title02 {
      width: 116px;
      position: absolute;
      top: 587px;
      left: 360px;
    }
    .text3 {
      font-size: 18px;
      color: #414142;
      position: absolute;
      top: 622px;
      left: 388px;
    }
    .peo02 {
      width: 568px;
      position: absolute;
      top: 672px;
      left: 375px;
    }
    .peo03 {
      width: 568px;
      position: absolute;
      top: 672px;
      left: 977px;
    }
    .peo04 {
      width: 568px;
      position: absolute;
      top: 972px;
      left: 375px;
    }
    .peo05 {
      width: 568px;
      position: absolute;
      top: 972px;
      left: 977px;
    }
    .line06 {
      width: 146px;
      position: absolute;
      top: 1238px;
      left: 1774px;
    }
  }
  .footer {
    width: 1920px;
    height: 170px;
    background: #000001;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #ABACB1;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-size: 15px;
        color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #ABACB1;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #FFFFFF;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }

  }
}
</style>


